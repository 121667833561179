import React, { useState, useEffect } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import FabButton from "&styled/FabButton";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageHeader from "&styled/page-header";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridCellParams,
} from "@mui/x-data-grid";
import { colorPalette } from "&config/colors";

export default function BillingReport(props: any) {
  const { onSearch, onDetail, data  } = props;
  const [pageSize, setPageSize] = React.useState(25);
  const [detailData, setDetailData] = useState([]);
  const [billingType, setBillingType] = useState("report");
  const [query, setQuery] = React.useState({
    dateFrom: new Date(),
    dateTo: new Date(),
    country: "Pakistan",
    currency: "PKR",
    page: 1
  });

  useEffect(() => {
    (async () => {
      await onSearch(query);
    })();
  }, [onSearch, query]);

  const handleDetail = async (row) => {
    setBillingType("detail");
    const params = {
      userTypeId: row.userTypeId,
      dateFrom: row.dateFrom,
      dateTo: row.dateTo,
      serviceName: row.serviceName,
    };
    const { payload } = await onDetail(params);
    setDetailData(payload);
  };

  const renderBtnShow = (row) => {
    return (
      <FabButton label="Show" onClick={() => handleDetail(row)}>
        <RemoveRedEyeRoundedIcon />
      </FabButton>
    );
  };

  let pkFormatter = new Intl.NumberFormat("en-PK", {
    style: "currency",
    currency: "PKR",
  });

  const columns: GridColDef[] = [
    //{
    //  field: "serviceId",
    //  headerName: "ID",
    //  width: 150,
    //  renderCell: ({ row }) => (
    //    <Box sx={{ color: "#6631F7", cursor: "pointer" }}>{row.serviceId}</Box>
    //  ),
    //  headerAlign: "left",
    //  align: "left",
    //},
    //{
    //  field: "transactionDate",
    //  headerName: "Transaction Date",
    //  width: 200,
    //  renderCell: ({ row }) => moment(row.transactionDate).format("DD/MM/YYYY"),
    //  headerAlign: "left",
    //  align: "left",
    //},
    {
      field: "country",
      headerName: "Country",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    //{
    //  field: "operator",
    //  headerName: "Operator",
    //  width: 200,
    //  headerAlign: "left",
    //  align: "left",
    //},
    {
      field: "currency",
      headerName: "Currency",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "serviceName",
      headerName: "Service Name",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalNumberOfTransactions",
      headerName: "Number Of Transactions",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      renderCell: ({ row }) => pkFormatter.format(row.totalAmount),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "waleeShareValue",
      headerName: "WFS Share",
      renderCell: ({ row }) => pkFormatter.format(row.waleeShareValue),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "merchantShareValue",
      headerName: "Merchant Share",
      renderCell: ({ row }) => pkFormatter.format(row.merchantShareValue),
      width: 150,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderBtnShow(row),
      width: 200,
      headerAlign: "center",
      align: "center",
    },
  ];
  const detailViewCols: GridColDef[] = [
    {
      field: "transactionId",
      headerName: "ID",
      width: 150,
      renderCell: ({ row }) => (
        <Box sx={{ color: "#6631F7", cursor: "pointer" }}>
          {row.transactionId}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "transactionDate",
      headerName: "Transaction Date",
      width: 150,
      renderCell: ({ row }) =>
        moment(row.transactionDate).format("DD/MM/YYYY HH:mm:A"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "msisdn",
      headerName: "Account #",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "operator",
      headerName: "Operator",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "serviceName",
      headerName: "Service Name",
      width: 200,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "amount",
      headerName: "Amount",
      renderCell: ({ row }) => pkFormatter.format(row.amount),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "waleeShareValue",
      headerName: "WFS Share",
      renderCell: ({ row }) => pkFormatter.format(row.waleeShareValue),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "merchantShareValue",
      headerName: "Merchant Share (96%)",
      renderCell: ({ row }) => pkFormatter.format(row.merchantShareValue),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  const getDatesFromPageHeader = (val:any, type:string) => {
    setQuery((prevState)=> ({...prevState, [type]: val}))
  }

  return (
    <>
      <PageHeader
        title="Billing Report"
        onSearch={onSearch}
        type="settlement"
        defaultCurrency="PKR"
        getDatesValues ={getDatesFromPageHeader}
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: `4px solid ${colorPalette.primary}`,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          "& .purple": {
            backgroundColor: colorPalette.primaryLight,
            color: "#3C3C3C",
          },
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={billingType === "report" ? (data[0]?.data || data || []) : detailData}
                columns={billingType === "report" ? columns : detailViewCols}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                getRowId={(row) => row?._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
                getCellClassName={(params: GridCellParams<number>) => {
                  if (
                    params.field === "carrierShareValue" ||
                    params.field === "merchantShareValue" ||
                    params.field === "waleeShareValue"
                  ) {
                    return "purple";
                  } else {
                    return "";
                  }
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
