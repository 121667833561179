import { colorPalette } from "&config/colors";
import * as React from "react";

export default function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.07"
      height="15.07"
      viewBox="0 0 15.07 15.07"
    >
      <g id="edit-3" transform="translate(-2.25 -2.129)">
        <path
          id="Path_44"
          data-name="Path 44"
          d="M12,20h7.184"
          transform="translate(-2.614 -3.551)"
          fill="none"
          stroke={colorPalette.primary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_45"
          data-name="Path 45"
          d="M13.147,3.371a1.537,1.537,0,0,1,2.255,0,1.747,1.747,0,0,1,0,2.378l-9.4,9.907L3,16.449l.752-3.17Z"
          transform="translate(0 0)"
          fill="none"
          stroke={colorPalette.primary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
