import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { HostedCheckout } from "./hostedCheckout.type";

const JAZZCASH_MS_URL = process.env.REACT_APP_JAZZCASH_URL;
const EASYPAISA_MS_URL = process.env.REACT_APP_EASYPAISA_URL;
const initialState: HostedCheckout = {};


const getSessionInfo = createAsyncThunk(
  "checkout/getSessionInfoCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/checkout/get/service?sessionId=${arg}`;
    
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname))
      );
      console.log("Success response from session info :", response.data);
     
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const checkoutWithJazzcash = createAsyncThunk(
  "checkout/checkoutCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/via-mw`;
      delete body.provider;
     
    try {
      /** make api call */
      const response = await axios.post(JAZZCASH_MS_URL!.concat(pathname), body)
     
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const checkoutWithEasypaisa = createAsyncThunk(
  "checkout/checkoutWithHashCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/via-mw`;
   
    try {
      /** make api call */
      const response = await axios.post(EASYPAISA_MS_URL!.concat(pathname), body);
    
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

// for easypaisa (CC), add initial status Pending
//const checkoutWithEasypaisa = createAsyncThunk(
//  "checkout/checkoutWithEasypaisaCallStatus",
//  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
//    const pathname = `/checkout/credit-card-checkout/easypaisa`;
//      const headers = {
//        Authorization: process.env.REACT_APP_ADMIN_CRED
//      }
//    try {
//      /** make api call */
//      const response = await trackPromise(
//        axios.post(WALEE_BASE_URL.concat(pathname), body, {headers})
//      );
//      console.log("Success response from checkout :", response.data);
     
//      return response.data;
//    } catch (e: any) {
//      console.error(e);
//      return rejectWithValue(e.response?.data);
//    }
//  }
//);


const hostedCheckoutSlice = createSlice({
  
  name: "hostedCheckout",

  initialState: initialState,

  reducers: {
    setHostedCheckout: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
  /**
   * Extra reducers are for handling action types.
   * Here thunk actions are handled
   */
  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getSessionInfo.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(getSessionInfo.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(getSessionInfo.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const hostedCheckoutReducer = hostedCheckoutSlice.reducer;

export const hostedCheckoutActions = { ...hostedCheckoutSlice.actions, getSessionInfo, checkoutWithJazzcash, checkoutWithEasypaisa };
