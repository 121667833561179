import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import FabButton from "&styled/FabButton";
import EditIcon from "&assets/icons/edit";
import DeleteIcon from "&assets/icons/delete";
import { warningAlert, successAlert, errorAlert } from "&config/swalGenerator";
import { getCurrentUser } from "&config/getCurrentUser";
import { loanAccountBalanceDetailActions } from "./loanAccountBalanceDetail.slice";
import DetailModal from "./detailModal";
import { MerchantInfoComponent } from "./merchantInfo";
import EyeIcon from "&assets/icons/eye";
import DocumentBlueIcon from "&assets/icons/document-blue";
import { colorPalette } from "&config/colors";

type ReduxProps = ConnectedProps<typeof connector>;

let pkFormatter = new Intl.NumberFormat("en-PK", {
  style: "currency",
  currency: "PKR",
});

const LoanAccountBalanceDetailComponent = (props: ReduxProps) => {
  const history = useHistory();
  const {
    state,
    getLoanAccountDetails,
    deleteLoanAccountDetail,
    merchantId,
    token,
  } = props;
  const { data } = state;
  const [pageSize, setPageSize] = useState(25);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const user = getCurrentUser(token);

  useEffect(() => {
    (async () => {
      await getLoanAccountDetails(merchantId);
    })();
  }, [getLoanAccountDetails, merchantId]);

  const handleDetailModalClose = () => {
    setDetailModalOpen(false);
  };

  const onDelete = async (event, id: string) => {
    const value = await warningAlert();
    if (value) {
      try {
        await deleteLoanAccountDetail(id);

        successAlert("Deleted", "Transaction has been deleted successfully!");
      } catch (ex) {
        errorAlert();
      }
    }
  };

  const renderButtons = (row) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        {renderBtnView(row)}
        {user?.userTypeId === "Walee" && renderBtnHistory(row)}
        {user?.userTypeId === "Walee" && renderBtnEdit(row)}
        {user?.userTypeId === "Walee" && renderBtnDelete(row)}
      </Box>
    );
  };
  const renderBtnDelete = (row) => {
    return (
      <FabButton
        label="Delete"
        color="secondary"
        onClick={(e: React.MouseEvent) => onDelete(e, row._id)}
      >
        <DeleteIcon />
      </FabButton>
    );
  };
  const renderBtnView = (row) => {
    return (
      <FabButton
        label="Show"
        onClick={(e: React.MouseEvent) => {
          const selected = data.find((d) => d._id === row._id);
          setSelectedTransaction(selected);
          setDetailModalOpen(true);
        }}
      >
        <EyeIcon />
      </FabButton>
    );
  };

  const renderBtnHistory = (row) => {
    return (
      <FabButton
        label="History"
        onClick={(e: React.MouseEvent) => {
          const selected = data.find((d) => d._id === row._id);
          setSelectedTransaction(selected);
          setDetailModalOpen(true);
        }}
      >
        <DocumentBlueIcon />
      </FabButton>
    );
  };
  const renderBtnEdit = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) =>
          history.push(`/loan-account-detail/form/${row._id}`)
        }
      >
        <EditIcon />
      </FabButton>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "transactionId",
      renderHeader: () => (
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "#3C3C3C",
            textTransform: "uppercase",
          }}
        >
          Transaction ID
        </Box>
      ),
      renderCell: ({ row }) => (
        <Box sx={{ color: "rgb(102, 49, 247)" }}>{row.transactionId}</Box>
      ),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "currency",
      renderHeader: () => (
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "#3C3C3C",
            textTransform: "uppercase",
          }}
        >
          Currency
        </Box>
      ),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "paymentMethod",
      renderHeader: () => (
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "#3C3C3C",
            textTransform: "uppercase",
          }}
        >
          Payment Method
        </Box>
      ),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalAmount",
      renderHeader: () => (
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "#3C3C3C",
            textTransform: "uppercase",
          }}
        >
          Total Amount
        </Box>
      ),
      renderCell: ({ row }) => <Box>{pkFormatter.format(row.totalAmount)}</Box>,
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalBilledAmount",
      renderHeader: () => (
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "#3C3C3C",
            textTransform: "uppercase",
          }}
        >
          Total Billed Amount
        </Box>
      ),
      renderCell: ({ row }) => (
        <Box>{pkFormatter.format(row.totalBilledAmount)}</Box>
      ),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "waleeCommission",
      renderHeader: () => (
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "#3C3C3C",
            textTransform: "uppercase",
          }}
        >
          Walee Commission
        </Box>
      ),
      renderCell: ({ row }) => (
        <Box>
          {pkFormatter.format(
            parseInt(row.totalAmount) - parseInt(row.totalBilledAmount)
          )}
        </Box>
      ),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "createdDate",
      renderHeader: () => (
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "#3C3C3C",
            textTransform: "uppercase",
          }}
        >
          Date/Time
        </Box>
      ),
      flex: 1,
      renderCell: ({ row }) =>
        moment(row.createdDate).format("DD MMM YYYY HH:mm:A"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 200,
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <>
      <PageHeader
        title={data[0]?.merchantName || "Account Transactions"}
        onAdd={() => history.push("/loan-account-detail/form/new")}
        addText="Create Transaction"
      />
      {/*Merchant info*/}
      <Box>
        <MerchantInfoComponent balanceSummary={data[0]?.loanAccount} />
      </Box>
      <Box
        sx={{
          marginY: "1rem",
          borderTop: `4px solid ${colorPalette.primary}`,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
                sx={{
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#F9F7FF",
                  },
                }}
              />
            </div>
          </CardContent>
        </Card>
        <DetailModal
          open={detailModalOpen}
          handleClose={handleDetailModalClose}
          values={selectedTransaction}
        />
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  state: state.loanAccountDetails,
  merchantId: ownProps.match.params.merchantId,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getLoanAccountDetails: loanAccountBalanceDetailActions.getLoanAccountDetails,
  deleteLoanAccountDetail:
    loanAccountBalanceDetailActions.deleteLoanAccountDetail,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const LoanAccountBalanceDetailComponentRedux = connector(
  LoanAccountBalanceDetailComponent
);

export { LoanAccountBalanceDetailComponentRedux as LoanAccountBalanceDetailComponent };
