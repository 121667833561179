import * as React from "react";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colorPalette } from "&config/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colorPalette.primary,
    },
  },
});

export default function ToggleView({ view, handleChange }) {
  return (
    <ThemeProvider theme={theme}>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleChange}
        size="small"
      >
        <ToggleButton value="list" aria-label="list" color="primary">
          <ViewListIcon />
        </ToggleButton>
        <ToggleButton value="module" aria-label="module" color="primary">
          <ViewModuleIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </ThemeProvider>
  );
}
