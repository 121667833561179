import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Products } from "./products.type";

/**
 * Initial state object
 */
const initialState: Products = {
  products: {
    current: 1,
    data: [],
    pages: 0,
    total: 0,
  },
};

const getProducts = createAsyncThunk(
  
  "products/makeProductsApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/products/get?page=${arg}`;
   
    const headers = {
      "Authorization": process.env.REACT_APP_ADMIN_PRODUCTS,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from merchants :", response.data);
     
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addProduct = createAsyncThunk(
  
  "products/addProductsApiCallStatus",
  async (arg: any, { rejectWithValue, getState }: any) => {
    const pathname = `/products/add`;
   
    const headers = {
      "x-auth-token": getState().login.token,
    };
    console.log(arg)
    try {
      /** make api call */
      //const response = await trackPromise(
      //  axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      //);
      //console.log("Success response from merchants :", response.data);
     
      //return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

/**
 * Feature slice Object
 * Automatically generates actions as per reducers
 */
const productsSlice = createSlice({
  /**
   * Unique feature name
   */
  name: "products",

  /**
   * Initial state object
   */
  initialState: initialState,

  reducers: {
    setProducts: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },

  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getProducts.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});


export const productsReducer = productsSlice.reducer;


export const productsActions = { ...productsSlice.actions, getProducts, addProduct };
