import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Services } from "./services.type";

/**
 * Initial state object
 */
const initialState: Services = {
  data: [],
};

// for service form, data required is (operators, merchants & carriers)
const getServiceData = createAsyncThunk(
  "services/getServicesCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const merchantPathname = `/merchants/get`;
    const carrierPathname = `/carriers/get`;
    const operatorPathname = `/operators/get`;

    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const [
        { data: merchants },
        { data: carriers },
        { data: operators },
      ] = await trackPromise(
        Promise.all([
          axios.get(WALEE_BASE_URL.concat(merchantPathname), { headers }),
          axios.get(WALEE_BASE_URL.concat(carrierPathname), { headers }),
          axios.get(WALEE_BASE_URL.concat(operatorPathname), { headers }),
        ])
      );
      const data = { merchants, carriers, operators };
      return data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getServices = createAsyncThunk(
  "services/getServicesCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/services/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      dispatch(servicesActions.setData(response.data));
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
// get by service _id
const getService = createAsyncThunk(
  "service/getServiceCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/services/get/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

// get  service by sid
const getServiceBySid = createAsyncThunk(
  "service/getServiceCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/services/get/sid/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addService = createAsyncThunk(
  "service/addServiceCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/services/add`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const editService = createAsyncThunk(
  "service/editServiceCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/services/edit/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const toggleServiceStatus = createAsyncThunk(
  "service/toggleServiceCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/services/edit/status/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      dispatch(servicesActions.setServiceStatus(body));
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const servicesSlice = createSlice({
  name: "services",

  initialState: initialState,

  reducers: {
    setServices: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setServiceStatus: (state, action) => {
      const { status, _id } = action.payload;
      const index = state.data.map((s) => s._id).indexOf(_id);
      state.data[index].status = status;
    },

    reset: () => initialState,
  },
});

export const servicesReducer = servicesSlice.reducer;
export const servicesActions = {
  ...servicesSlice.actions,
  getServices,
  getService,
  getServiceBySid,
  addService,
  editService,
  getServiceData,
  toggleServiceStatus,
};
