import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  getGridStringOperators,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { oneTinePurchaseActions } from "./oneTinePurchase.slice";
import { colorPalette } from "&config/colors";

type ReduxProps = ConnectedProps<typeof connector>;

const OneTinePurchaseComponent = (props: ReduxProps) => {
  const { state, getPurchases, searchPurchases } = props;
  const { purchases } = state;
  const { data, current, total } = purchases;
  const [query, setQuery] = React.useState({});
  const [pageSize] = React.useState(25);
  const [sortByFilter, setSortByFilter] = React.useState({});
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 25,
    rows: [],
    loading: false,
  });

  let pkFormatter = new Intl.NumberFormat("en-PK", {
    style: "currency",
    currency: "PKR",
  });

  const columns: GridColDef[] = [
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "operator",
      headerName: "Operator",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "merchantName",
      headerName: "Merchant",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "serviceName",
      headerName: "Service",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "msisdn",
      headerName: "MSISDN",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "amount",
      headerName: "Amount",
      renderCell: ({ row }) => pkFormatter.format(row.amount),
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "transactionStatus",
      headerName: "Transaction Status",
      renderCell: ({ row }) => renderStatus(row),
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "dateOfTransaction",
      headerName: "Subscription Date",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell: ({ row }) =>
        moment(row.dateOfTransaction).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {
      await getPurchases(1);
    })();
  }, [getPurchases]);

  const onPageChange = async (page) => {
    // check if filters in place
    if (Object.keys(query).length > 0) {
      const searchObj = { ...query, page: page + 1 };
      await searchPurchases(searchObj);
    } else {
      await getPurchases(page + 1);
    }
  };

  const onFilterChange = async (obj) => {
    if (!obj.items[0].value) {
      setQuery({});
    }
    const searchObj = {};
    let performSearch = false;
    if (
      obj.items[0].columnField === "dateOfTransaction" &&
      obj.items[0].value
    ) {
      searchObj["dateOfTransaction"] = moment(obj.items[0].value);
      performSearch = true;
    } else if (obj.items[0].columnField && obj.items[0].value) {
      if (obj.items[0].operatorValue === "equals") {
        searchObj[obj.items[0].columnField] = obj.items[0].value;
        performSearch = true;
      } else if (obj.items[0].operatorValue === "contains") {
        searchObj[obj.items[0].columnField] = {
          $regex: obj.items[0].value,
          $options: "i",
        };
        performSearch = true;
      }
    }
    if (performSearch) {
      setQuery(searchObj);
      await searchPurchases({ searchObj, page: 1 });
    }
  };

  const onSortChange = async (obj) => {
    const { field, sort } = obj[0];
    setSortByFilter({ field, sort });
    await searchPurchases({
      searchObj: query,
      sortObj: { field, sortBy: sort },
      page: 1,
    });
  };
  const renderStatus = (row) => {
    return (
      <Box
        sx={{
          backgroundColor:
            row.transactionStatus !== "Success" ? "#ECECEC" : "#D6FFD9",
          color: row.transactionStatus !== "Success" ? "#727272" : "#0FA01A",
          width: "85px",
          textAlign: "center",
          borderRadius: "5px",
          padding: "5px",
          fontSize: "12px",
          lineHeight: "18px",
          cursor: "pointer",
        }}
      >
        {row.transactionStatus}
      </Box>
    );
  };

  return (
    <>
      <PageHeader title="One-Time Purchase" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: `4px solid ${colorPalette.primary}`,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                onSortModelChange={onSortChange}
                onFilterModelChange={onFilterChange}
                onPageChange={onPageChange}
                onPageSizeChange={(pageSize) =>
                  setRowsState((prev) => ({ ...prev, pageSize }))
                }
                rows={data}
                columns={columns}
                pageSize={pageSize}
                page={current - 1}
                rowCount={total}
                rowsPerPageOptions={[]}
                getRowId={(row) => row._id}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.oneTimePurchase,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getPurchases: oneTinePurchaseActions.getPurchases,
  searchPurchases: oneTinePurchaseActions.searchPurchases,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const OneTinePurchaseComponentRedux = connector(OneTinePurchaseComponent);

export { OneTinePurchaseComponentRedux as OneTinePurchaseComponent };
