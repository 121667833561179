import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function SummaryCard({ label, value, icon: Icon }) {
  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Icon />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ color: "#111111", fontSize: "16px" }}>{label}</Box>
            <Box sx={{ fontSize: "20px", fontWeight: "bold" }}>{value}</Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
