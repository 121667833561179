import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { RootState } from "&store/store";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import ToggleView from "&styled/toggleView/toggleView.component";
import { getCurrentUser } from "&config/getCurrentUser";
import { loanAccountBalanceActions } from "./loanAccountBalance.slice";
import CardView from "./cardView";
import { colorPalette } from "&config/colors";

let pkFormatter = new Intl.NumberFormat("en-PK", {
  style: "currency",
  currency: "PKR",
});

type ReduxProps = ConnectedProps<typeof connector>;

const LoanAccountBalanceComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getLoanAccounts, token } = props;
  const { data } = state;
  const [pageSize, setPageSize] = useState(25);
  const [view, setView] = useState("list");

  const user = getCurrentUser(token)
 
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
  };

  useEffect(() => {
    (async () => {
      await getLoanAccounts();
    })();
  }, [getLoanAccounts]);

  const renderButtons = (row) => {
    return (
      <Box
        sx={{
          padding: "0.3rem 0.8rem",
          color: "#ffffff",
          borderRadius: "4px",
          backgroundColor: "rgb(102, 49, 247)",
          cursor: "pointer",
        }}
        onClick={() => history.push(`/loan-account-detail/${row.userTypeId}`)}
      >
        Detail
      </Box>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "merchantName",
      headerName: "Merchant",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "availableBalance",
      headerName: "Available Balance (PKR)",
      flex: 1,
      renderCell: ({ row }) => pkFormatter.format(row.availableBalance),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalTransactions",
      headerName: "Total Transactions Disbursed",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalTransactionsAmount",
      headerName: "Total Amount Disbursed (PKR)",
      flex: 1,
      renderCell: ({ row }) =>
        pkFormatter.format(row.totalTransactionsAmount || 0),
      headerAlign: "left",
      align: "left",
    },

    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 200,
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <>
      <PageHeader
        title="Account(s)"
        onAdd={user?.userTypeId === "Walee" ? () => history.push("/loan-account-detail/form/new") : null}
        addText={user?.userTypeId === "Walee" ? "Create Transaction": null}
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: `4px solid ${colorPalette.primary}`,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <ToggleView view={view} handleChange={handleChange} />
        </Box>
        {view === "module" && <CardView data={data} />}

        {view === "list" && (
          <Card>
            <CardContent>
              <div style={{ height: 650, width: "100%" }}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  pageSize={pageSize}
                  rowsPerPageOptions={[25, 50, 100, 500]}
                  getRowId={(row) => row._id}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  disableSelectionOnClick
                />
              </div>
            </CardContent>
          </Card>
        )}
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.loanAccounts,
  token: state.login.token,
});

const mapDispatchToProps = {
  getLoanAccounts: loanAccountBalanceActions.getLoanAccounts,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const LoanAccountBalanceComponentRedux = connector(LoanAccountBalanceComponent);

export { LoanAccountBalanceComponentRedux as LoanAccountBalanceComponent };
